import { useEffect, useState } from "react";
import { Container, Row, Col, Button, Card, Form } from "react-bootstrap";
import useAuth from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { IUserRegister } from "interfaces/Auth";

const Register = () => {
  const navigate = useNavigate();
  const { signUp, logIn, resetAuth, error } = useAuth();

  const [registration, setRegistration] = useState<IUserRegister>({
    name: "",
    username: "",
    password: "",
    confPassword: "",
  });
  const [validated, setValidated] = useState(false);
  const [logInRequired, setLogInRequired] = useState(false);
  const [confUsername, setConfUsername] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (error) {
      if (error.code === "UsernameExistsException") {
        setErrorMessage(
          "It appears that you have already created an account using this email address. Please try to login."
        );
      } else {
        setErrorMessage(
          "Something has gone wrong signing you up, please check your email address and ensure you use a strong password."
        );
      }
    }
  }, [error]);

  const handleSubmit = async (e: any) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    setValidated(false);
    const name = form[0].value;
    const username = form[1].value;
    const password = form[2].value;
    const confPassword = form[3].value;
    if (name && username && password && confPassword) {
      if (password !== confPassword) {
        setErrorMessage("Your passwords must match");
      } else {
        signUp(name, username, password);
        setConfUsername(username);
      }
    }
    setValidated(true);
  };

  const handleChange = async (e: any) => {
    e.persist();
    if (registration) {
      setRegistration({ ...registration, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    if (registration && logInRequired) {
      logIn(registration.username, registration.password);
      setTimeout(() => {
        navigate("/createAccount");
      }, 1000);
    }
  }, [confUsername, registration, errorMessage, logInRequired]);

  useEffect(() => {
    resetAuth();
  }, []);

  return (
    <>
      <Container>
        <Card>
          <Card.Header>
            <span className="wrap-1">Online </span>{" "}
            <span className="wrap-2">Account</span>
          </Card.Header>
          <Card.Body>
            <p>You can register for a free online account below.</p>
            <p>
              If you already have an account, please{" "}
              <Link to="/login">login</Link> instead.
            </p>

            <Row>
              <Col>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Label id="namelabel">Name</Form.Label>
                      <Form.Control
                        name="name"
                        placeholder="Your name"
                        aria-labelledby="namelabel"
                        aria-required="true"
                        required
                        defaultValue={registration.username}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Email is required
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label id="usernamelabel">Email</Form.Label>
                      <Form.Control
                        name="username"
                        placeholder="Your email address"
                        aria-labelledby="usernamelabel"
                        aria-required="true"
                        required
                        type="email"
                        defaultValue={registration.username}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Email address is required
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label id="passwordlabel">Password</Form.Label>
                      <Form.Control
                        name="password"
                        aria-labelledby="passwordlabel"
                        aria-required="true"
                        type="password"
                        required
                        defaultValue={registration.password}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Password is required
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label id="confPasswordlabel">
                        Confirm Password
                      </Form.Label>
                      <Form.Control
                        name="confPassword"
                        aria-labelledby="confPasswordlabel"
                        aria-required="true"
                        type="password"
                        required
                        defaultValue={registration.confPassword}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Password confirmation is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  {errorMessage && (
                    <Row>
                      <Col>
                        <div className="error gap-bottom">{errorMessage}</div>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      <Button variant="primary" type="submit">
                        Register
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default Register;
