import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, Tabs, Tab, Button } from "react-bootstrap";
import { PencilSquare } from "react-bootstrap-icons";
import useAuth from "context/AuthContext";
import usePlanning from "context/PlanningContext";

const Account = () => {
  const { profile } = useAuth();
  const { joinSession, deleteSession } = usePlanning();

  const handleJoinSession = (joinCode: string) => {
    joinSession(joinCode);
  };

  const handleDeleteSession = (code: string) => {
    deleteSession(code);
  };

  const handleTabChange = (key: any) => {
    if (key && key === "orders") {
    }
  };

  useEffect(() => {}, [profile]);

  return (
    <>
      <Container>
        <Card className="gap-bottom footer-spacer">
          <Card.Header>
            <h1>My Account</h1>
          </Card.Header>
          <Card.Body>
            {profile ? (
              <>
                <Tabs
                  id="accountTabs"
                  defaultActiveKey="details"
                  className="mb-3"
                  onSelect={(e) => handleTabChange(e)}
                >
                  <Tab eventKey="details" title="Account details">
                    <Row>
                      <Col xs={9}>
                        <h2>Your details</h2>
                      </Col>
                      <Col xs={3} className="align-right">
                        <Link to="/updateProfile">
                          <PencilSquare size={30} color="#6f0d6e" />
                        </Link>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <dl>
                          <dt>Name:</dt>
                          <dd>{profile.name}</dd>
                          <dt>Email:</dt>
                          <dd>{profile.email}</dd>
                        </dl>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="sessions" title="Sessions">
                    <Row>
                      <Col xs={9}>
                        <>
                          <h2>Your sessions</h2>
                          <Link
                            to="/createSession"
                            className="btn btn-primary margin-bottom margin-right"
                          >
                            Create new session
                          </Link>
                          <Link
                            to="/joinSession"
                            className="btn btn-primary margin-bottom"
                          >
                            Join existing session
                          </Link>
                          {profile.sessions && profile.sessions.length > 0 ? (
                            <ul className="clear-list">
                              {profile.sessions.map((session) => {
                                return (
                                  <li key={session.code} className="margin-top">
                                    <Row>
                                      <Col xs={3} sm={3} md={2} lg={2} xl={1}>
                                        <Button
                                          variant="danger"
                                          onClick={() =>
                                            handleDeleteSession(session.code)
                                          }
                                          className="margin-right"
                                        >
                                          Delete
                                        </Button>
                                      </Col>
                                      <Col xs={3} sm={2} md={2} lg={1} xl={1}>
                                        <Button
                                          variant="success"
                                          onClick={() =>
                                            handleJoinSession(session.joinCode)
                                          }
                                        >
                                          Join
                                        </Button>
                                      </Col>
                                      <Col xs={6} sm={7} md={8} lg={9} xl={10}>
                                        {session.title}
                                      </Col>
                                    </Row>
                                  </li>
                                );
                              })}
                            </ul>
                          ) : (
                            <p>
                              You currently have no sessions that your account
                              is linked to.
                            </p>
                          )}
                        </>
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
              </>
            ) : (
              <></>
            )}
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default Account;
