import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Container, Row, Col, Button, Card, Form } from "react-bootstrap";
import useAuth from "context/AuthContext";
import Loading from "components/Loading";

const Login = () => {
  let navigate = useNavigate();
  const { logIn, logOut, isAuthenticated, profile, error } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e: any) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    setValidated(false);
    const username = form[0].value;
    const password = form[1].value;
    setIsLoading(true);
    logIn(username, password);

    setValidated(true);
  };

  useEffect(() => {
    if (error) {
      setIsLoading(false);
      if (error.code === "UsernameExistsException") {
        setErrorMessage(
          "It appears that you have already created an account using this email address. Please try to login."
        );
      } else {
        setErrorMessage(
          "Something has gone wrong logging you in, please check your login credentials."
        );
      }
    }
  }, [error]);

  useEffect(() => {
    if (profile) {
      navigate("/account");
    }
  }, [profile]);

  useEffect(() => {}, [isLoading]);

  useEffect(() => {
    if (isAuthenticated) {
      logOut();
      navigate("/login");
    }
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <h1>Log in</h1>
            </Card.Header>
            <Card.Body>
              <Loading isLoading={isLoading} />
              <p>Please login or register for an account.</p>
              <p>You can also reset your password if you have forgotten it.</p>
              <Row>
                <Col>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Row>
                      <Form.Group className="col-xs-12 col-sm-9 col-md-8 col-lg-6">
                        <Form.Label id="usernamelabel">Username</Form.Label>
                        <Form.Control
                          name="username"
                          placeholder="email@domain.com"
                          aria-labelledby="usernamelabel"
                          aria-required="true"
                          required
                          type="email"
                        />
                        <Form.Control.Feedback type="invalid">
                          Username is required
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group className="col-xs-12 col-sm-9 col-md-8 col-lg-6">
                        <Form.Label id="passwordlabel">Password</Form.Label>
                        <Form.Control
                          name="password"
                          aria-labelledby="passwordlabel"
                          aria-required="true"
                          type="password"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Password is required
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    {errorMessage && (
                      <Row>
                        <Col>
                          <div className="error gap-bottom">{errorMessage}</div>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col xs={6} sm={3} md={6} lg={2}>
                        <Button
                          variant="primary"
                          type="submit"
                          className="margin-top"
                        >
                          Log In
                        </Button>
                      </Col>
                      <Col xs={6} sm={3} md={6} lg={2}>
                        <Link
                          to="/register"
                          className="btn btn-secondary margin-top"
                        >
                          Register
                        </Link>
                      </Col>
                      <Col xs={12} sm={6} md={12} lg={4}>
                        <Link
                          to="/forgotpassword"
                          className="btn btn-secondary margin-top"
                        >
                          Forgotten Password
                        </Link>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
