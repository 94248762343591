import { useEffect, useState } from "react";

type EstimateCardProps = {
  value: number;
  text: string;
  active: boolean;
  selectedValue: number;
  onSelected(value: number): void;
};

const EstimateCard = ({
  value,
  text,
  active,
  selectedValue,
  onSelected,
}: EstimateCardProps) => {
  const [estimateClass, setEstimateClass] = useState("estimate-card active");

  const handleSelected = (value: number) => {
    onSelected(value);
  };

  useEffect(() => {
    if (selectedValue === value) {
      if (active) {
        setEstimateClass("estimate-card active selected");
      } else {
        setEstimateClass("estimate-card disabled selected");
      }
    } else {
      if (active) {
        setEstimateClass("estimate-card active");
      } else {
        setEstimateClass("estimate-card disabled");
      }
    }
  }, [selectedValue]);

  useEffect(() => {}, [estimateClass]);

  return (
    <>
      {active ? (
        <div className={estimateClass} onClick={() => handleSelected(value)}>
          {text}
        </div>
      ) : (
        <div className={estimateClass}>{text}</div>
      )}
    </>
  );
};

export default EstimateCard;
