import usePlanning from "context/PlanningContext";
import { useState } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";

const JoinSession = () => {
  const { joinSession } = usePlanning();
  const [validated, setValidated] = useState(false);
  const [sessionCode, setSessionCode] = useState("");

  const handleCodeChange = (e: any) => {
    e.persist();
    setSessionCode(e.target.value);
  };

  const handleSubmitJoin = (e: any) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    setValidated(true);
    if (form.checkValidity()) {
      joinSession(sessionCode);
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <h1>Join Session</h1>
            </Card.Header>
            <Card.Body>
              <p>Please enter your session code.</p>
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmitJoin}
              >
                <Form.Group className="mb-3" controlId="code">
                  <Form.Label>Code</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={sessionCode}
                    onChange={handleCodeChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Session Code is required
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Row}>
                  <Button variant="primary" type="submit">
                    Join
                  </Button>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default JoinSession;
