import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import useAuth from "context/AuthContext";
import { IUpdateProfile } from "interfaces/Auth";
import Loading from "components/Loading";

const UpdateProfile = () => {
  const navigate = useNavigate();
  const { profile, updateProfile } = useAuth();
  const [validated, setValidated] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [userProfile, setUserProfile] = useState<IUpdateProfile>();

  const handleChange = async (e: any) => {
    e.persist();
    if (userProfile) {
      setUserProfile({ ...userProfile, [e.target.id]: e.target.value });
    }
  };

  const handleSubmit = (e: any) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() && userProfile) {
      setIsUpdating(true);
      updateProfile(userProfile);
    }
    setValidated(true);
  };

  useEffect(() => {
    if (validated) {
      navigate("/account");
    }
  }, [validated, isUpdating]);

  useEffect(() => {
    if (profile) {
      const initUpdateProfile: IUpdateProfile = {
        name: profile.name,
        email: profile.email,
      };
      setUserProfile({ ...initUpdateProfile });
    }
  }, []);

  return (
    <>
      <Container>
        <Card className="gap-bottom footer-spacer">
          <Card.Header>
            <h1>Update Profile</h1>
          </Card.Header>
          <Card.Body>
            {userProfile ? (
              <Row>
                <Col>
                  <h2>Personal details</h2>
                  <Loading isLoading={isUpdating} />
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Form.Group className="mb-3" controlId="name">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={userProfile.name}
                        onChange={handleChange}
                        pattern="^[a-zA-Z][a-zA-Z- ]{1,199}$"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Name is required
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        defaultValue={userProfile.email}
                        onChange={handleChange}
                        required
                      />
                      <Form.Text id="passwordHelpBlock" muted>
                        This is not the email/username that you use to log in
                        with. This is the default email address to send approved
                        notifications to. This cannot be changed via your
                        profile.
                      </Form.Text>
                      <Form.Control.Feedback type="invalid">
                        Email is required
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Row>
                      <Col xs={6}>
                        <Form.Group>
                          <Button variant="primary" type="submit">
                            Update
                          </Button>
                        </Form.Group>
                      </Col>
                      <Col xs={6} className="align-right">
                        <Link to="/account" className="btn btn-outline-primary">
                          Cancel
                        </Link>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default UpdateProfile;
