import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import useAuth from "context/AuthContext";

const Home = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Container>
      <Row>
        <Col>
          <h1>Welcome to Sprint Planning.</h1>
          <p>
            This is an online tool to make planning your sprints easier by
            importing all of your Agile Tickets into one place and voting as a
            team.
          </p>

          {isAuthenticated ? (
            <>
              <p>
                You can manage all aspects of the sprint planning from your
                account which can be accessed on the{" "}
                <Link to="/account">My Account</Link> page.
              </p>
            </>
          ) : (
            <>
              <p>
                If this is your first time here, please{" "}
                <Link to="/register">Register</Link> for a free account
                otherwise please <Link to="/login">login</Link>.
              </p>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
