import useAuth from "context/AuthContext";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";

const ForgotPassword = () => {
  let navigate = useNavigate();
  const { sendVerificationCodeForForgotPassword, updatePassword } = useAuth();
  const [validated, setValidated] = useState(false);
  const [confValidated, setConfValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [codeReqSent, setCodeReqSent] = useState(false);
  const [username, setUsername] = useState("");

  const handleSubmit = async (e: any) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    setValidated(false);
    setUsername(form[0].value);
    sendVerificationCodeForForgotPassword(form[0].value);
    setCodeReqSent(true);
    setValidated(true);
  };

  const handleUpdateSubmit = async (e: any) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    setConfValidated(false);
    updatePassword(username, form[0].value, form[1].value);
    setConfValidated(true);
    navigate("/account");
  };

  useEffect(() => {}, [codeReqSent]);

  return (
    <>
      <Container>
        <Card>
          <Card.Header>
            <h1>Forgotten Password</h1>
          </Card.Header>
          <Card.Body>
            {!codeReqSent && (
              <>
                <p>
                  Please enter your email address you used to register your
                  account.
                </p>
                <p>
                  A verification code will then be sent to this email address.
                </p>

                <Row>
                  <Col>
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <Row>
                        <Form.Group className="col-xs-12 col-sm-9 col-md-8 col-lg-6">
                          <Form.Label id="usernamelabel">Username</Form.Label>
                          <Form.Control
                            name="username"
                            placeholder="Your email address"
                            aria-labelledby="usernamelabel"
                            aria-required="true"
                            required
                            type="email"
                          />
                          <Form.Control.Feedback type="invalid">
                            Username is required
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      {errorMessage && (
                        <Row>
                          <Col>
                            <div className="error margin-bottom">
                              {errorMessage}
                            </div>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          <Button
                            variant="primary"
                            type="submit"
                            className="margin-top"
                          >
                            Get code
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </>
            )}
            {codeReqSent && (
              <>
                <p>
                  Please enter your verification code and your new password.
                </p>
                <p>You can then log in with these new credentials.</p>
                <Row>
                  <Col>
                    <Form
                      noValidate
                      validated={confValidated}
                      onSubmit={handleUpdateSubmit}
                    >
                      <Row>
                        <Form.Group className="col-xs-12 col-sm-9 col-md-8 col-lg-6">
                          <Form.Label id="validationCodelabel">
                            Verification Code
                          </Form.Label>
                          <Form.Control
                            name="validationCode"
                            placeholder="Your validation code"
                            aria-labelledby="validationCodelabel"
                            aria-required="true"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Validation code is required
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group className="col-xs-12 col-sm-9 col-md-8 col-lg-6">
                          <Form.Label id="passwordlabel">
                            New Password
                          </Form.Label>
                          <Form.Control
                            name="password"
                            aria-labelledby="passwordlabel"
                            aria-required="true"
                            type="password"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Password is required
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      {errorMessage && (
                        <Row>
                          <Col>
                            <div className="error gap-bottom">
                              {errorMessage}
                            </div>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          <Button
                            variant="primary"
                            type="submit"
                            className="gap-top"
                          >
                            Update Password
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </>
            )}
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default ForgotPassword;
