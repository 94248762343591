import { useLocation, useNavigate } from "react-router-dom";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useContextHttp from "infrastructure/useContextHttp";
import useAuth from "./AuthContext";
import {
  ISession,
  IVotes,
  CreatePlanningRequest,
  AddTicketRequest,
  IBulkTicket,
  BulkAddTicketRequest,
  ActivateTicketRequest,
  VoteTicketRequest,
  AlterStateTicketRequest,
  GetVoteResultRequest,
  AllocateEstimateRequest,
  FinishVotingRequest,
  PlanningResponse,
  DeleteSessionRequest,
} from "interfaces/Planning";
import { IPlanningContext } from "./IPlanningContext";

const PlanningContext = createContext<IPlanningContext>({} as IPlanningContext);

export const PlanningProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  let navigate = useNavigate();
  const { profile, reloadProfile } = useAuth();
  const {
    data: voteResponse,
    error: voteError,
    sendRequest: voteRequest,
  } = useContextHttp();
  const {
    data: deleteSessionResponse,
    error: deleteSessionError,
    sendRequest: deleteSessionRequest,
  } = useContextHttp();
  const {
    data: redirectResponse,
    error: redirectError,
    sendRequest: redirectRequest,
  } = useContextHttp();
  const {
    data: noRedirectResponse,
    error: noRedirectError,
    sendRequest: noRedirectRequest,
  } = useContextHttp();

  const [session, setSession] = useState<ISession>({
    owner: { userId: "", name: "" },
    title: "",
    code: "",
    joinCode: "",
    showUserNames: false,
    votingActive: false,
    users: [],
    tickets: [],
  });
  const [pollingId, setPollingId] = useState<NodeJS.Timeout>();
  const [votes, setVotes] = useState<IVotes>({ votes: [] });

  const location = useLocation();

  useEffect(() => {
    if (pollingId && location.pathname != "/session") {
      resetPollingId();
    }
    if (location.pathname === "/account") {
      reloadProfile();
    }
  }, [location.pathname]);

  const createSession = (name: string, showUserNames: boolean) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/planning/create`;
    const request: CreatePlanningRequest = {
      name: name,
      showUserNames: showUserNames,
    };
    redirectRequest(url, "POST", request);
  };

  const joinSession = (code: string) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/planning/join/${code}`;
    redirectRequest(url, "GET", null, true);
  };

  const deleteSession = (code: string) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/planning/delete`;
    const request: DeleteSessionRequest = {
      code: code,
    };
    deleteSessionRequest(url, "POST", request);
  };

  const allocatePollingId = (id: NodeJS.Timeout) => {
    setPollingId(id);
  };

  const resetPollingId = () => {
    clearInterval(pollingId);
    setPollingId(undefined);
  };

  const addTicket = (key: string, title: string) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/planning/addTicket`;
    const request: AddTicketRequest = {
      code: session.code,
      key: key,
      title: title,
    };
    noRedirectRequest(url, "POST", request);
  };

  const bulkAddTickets = (code: string, tickets: IBulkTicket[]) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/planning/bulkAddTickets`;
    const request: BulkAddTicketRequest = {
      code: code,
      tickets: [],
    };

    tickets.map((ticket) => {
      request.tickets.push({
        key: ticket.key,
        title: ticket.title,
      });
    });

    noRedirectRequest(url, "POST", request);
  };

  const setSelectedTicket = (id: string) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/planning/selectTicket`;
    const request: ActivateTicketRequest = {
      code: session.code,
      id: id,
    };
    noRedirectRequest(url, "POST", request);
  };

  const getSession = (code: string) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/planning/session/${code}`;
    noRedirectRequest(url, "GET");
  };

  const startVoting = () => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/planning/startVoting/${session.code}`;
    noRedirectRequest(url, "GET");
  };

  const stopVoting = () => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/planning/stopVoting/${session.code}`;
    noRedirectRequest(url, "GET");
  };

  const voteOnTicket = (id: string, vote: number) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/planning/vote`;
    const request: VoteTicketRequest = {
      code: session.code,
      ticketId: id,
      vote: vote,
    };
    noRedirectRequest(url, "POST", request);
  };

  const deSelectTicket = (id: string) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/planning/deSelectTicket`;
    const request: AlterStateTicketRequest = {
      code: session.code,
      id: id,
    };
    noRedirectRequest(url, "POST", request);
  };

  const removeTicket = (id: string) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/planning/removeTicket`;
    const request: AlterStateTicketRequest = {
      code: session.code,
      id: id,
    };
    noRedirectRequest(url, "POST", request);
  };

  const getVoteResults = (id: string) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/planning/voteResults`;
    const request: GetVoteResultRequest = {
      code: session.code,
      id: id,
    };
    voteRequest(url, "POST", request);
  };

  const allocateEstimate = (id: string, estimate: number) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/planning/allocateEstimate`;
    const request: AllocateEstimateRequest = {
      code: session.code,
      id: id,
      estimate: estimate,
    };
    noRedirectRequest(url, "POST", request);
  };

  const finishVoting = (id: string) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/planning/finishVoting`;
    const request: FinishVotingRequest = {
      code: session.code,
      id: id,
    };
    noRedirectRequest(url, "POST", request);
  };

  useEffect(() => {
    if (voteError) {
      console.log("Error", JSON.stringify(voteError));
    }
    if (voteResponse) {
      setVotes(voteResponse as IVotes);
    }
  }, [voteResponse, voteError]);

  useEffect(() => {
    if (noRedirectError) {
      console.log("Error", JSON.stringify(noRedirectError));
    }
    if (noRedirectResponse) {
      MapToSession(noRedirectResponse as PlanningResponse);
    }
  }, [noRedirectResponse, noRedirectError]);

  useEffect(() => {
    if (redirectError) {
      console.log("Error", JSON.stringify(redirectError));
    }
    if (redirectResponse) {
      MapToSession(redirectResponse as PlanningResponse, "/session");
    }
  }, [redirectResponse, redirectError]);

  useEffect(() => {
    if (deleteSessionError) {
      console.log("Error", JSON.stringify(deleteSessionError));
    }
    if (deleteSessionResponse) {
      reloadProfile();
    }
  }, [deleteSessionResponse, deleteSessionError]);

  useEffect(() => {}, [profile]);

  /* Private Code */

  const MapToSession = (response: PlanningResponse, target: string = "") => {
    setSession({
      owner: response.owner,
      title: response.title,
      code: response.code,
      joinCode: response.joinCode,
      showUserNames: response.showUserNames,
      votingActive: response.votingActive,
      users: response.users,
      tickets: response.tickets,
    });
    if (target !== "") {
      navigate(target);
    }
  };

  const memoedValue = useMemo(
    () => ({
      session,
      votes,
      pollingId,
      createSession,
      joinSession,
      deleteSession,
      allocatePollingId,
      resetPollingId,
      getSession,
      addTicket,
      removeTicket,
      bulkAddTickets,
      setSelectedTicket,
      voteOnTicket,
      deSelectTicket,
      startVoting,
      stopVoting,
      getVoteResults,
      allocateEstimate,
      finishVoting,
    }),
    [session, pollingId]
  );
  return (
    <PlanningContext.Provider value={memoedValue}>
      {children}
    </PlanningContext.Provider>
  );
};

export default function usePlanning() {
  return useContext(PlanningContext);
}
