import useAuth from "context/AuthContext";
import { useEffect } from "react";

const Logout = () => {
  const { logOut } = useAuth();

  useEffect(() => {
    logOut();
  }, []);

  return <></>;
};

export default Logout;
