import useAuth from "context/AuthContext";
import { useEffect, useState } from "react";
import { Container, Card, Button, Col, Form, Row } from "react-bootstrap";

const ConfirmAccount = () => {
  const { confirmSignUp, resendConfCode, error } = useAuth();
  const [validated, setValidated] = useState(false);
  const [showResent, setShowResent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmitCode = async (e: any) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    setValidated(false);
    const code = form[0].value;
    confirmSignUp(code);
    setValidated(true);
  };

  const resendCode = () => {
    resendConfCode();
    setShowResent(true);
  };

  useEffect(() => {
    if (showResent) {
      setTimeout(() => {
        setShowResent(false);
      }, 5000);
    }
  }, [showResent]);

  useEffect(() => {
    if (error) {
      if (error.code === "LimitExceededException") {
        setErrorMessage(
          "We cannot re-send your confirmation code. Please try later"
        );
      }
    }
  }, [error]);

  return (
    <Container fluid>
      <Card>
        <Card.Header>
          <span className="wrap-1">Online </span>{" "}
          <span className="wrap-2">Account</span>
        </Card.Header>
        <Card.Body>
          {errorMessage !== "" ? (
            <Row>
              <Col>{errorMessage}</Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col>
                  <p>
                    Please check your email for a confirmation code and enter it
                    here. If you have not received it then you can Resend the
                    code.
                  </p>
                </Col>
              </Row>
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmitCode}
              >
                <Row>
                  <Form.Group className="mb-3">
                    <Form.Label id="confCodelabel">
                      Confirmation Code
                    </Form.Label>
                    <Form.Control
                      name="confcode"
                      aria-labelledby="confCodelabel"
                      aria-required="true"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Code is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit">
                      Confirm Account
                    </Button>
                  </Col>
                  <Col>
                    {showResent ? (
                      <p>Your code has been resent</p>
                    ) : (
                      <Button variant="secondary" onClick={() => resendCode()}>
                        Resend Code
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ConfirmAccount;
