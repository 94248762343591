import { ThreeDots } from "react-loader-spinner";

type LoadingProps = {
  isLoading: boolean;
};

const Loading = ({ isLoading }: LoadingProps) => {
  return (
    <>
      {isLoading && (
        <div className="loader">
          <ThreeDots color="#6f0d6e" height={40} width={100} />
        </div>
      )}
    </>
  );
};

export default Loading;
