import useAuth from "context/AuthContext";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

const Header = () => {
  const { isAuthenticated } = useAuth();
  return (
    <header>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Link to="/" className="navbar-brand">
            Sprint Planning
          </Link>
          <Navbar.Toggle aria-controls="navbar" />
          <Navbar.Collapse id="navbar">
            <Nav className="me-auto">
              <Link to="/" className="nav-link">
                Home
              </Link>
              {isAuthenticated ? (
                <>
                  <Link to="/account" className="nav-link">
                    My Account
                  </Link>
                  <Link to="/logout" className="nav-link">
                    Log out
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/login" className="nav-link">
                    Login
                  </Link>
                  <Link to="/register" className="nav-link">
                    Register
                  </Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
