import { BrowserRouter as Router } from "react-router-dom";
import RouteTable from "infrastructure/routeTable";
import { Col, Container, Nav, Row } from "react-bootstrap";
import "styling/app.scss";
import { PlanningProvider } from "context/PlanningContext";
import { Navbar } from "react-bootstrap";
import { AuthProvider } from "context/AuthContext";
import Header from "infrastructure/header";

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <PlanningProvider>
          <Container fluid>
            <Header />
            <RouteTable />
            <Navbar fixed="bottom">
              <Container fluid>
                <Nav>
                  <Nav.Item>v {process.env.REACT_APP_WEB_VERSION}</Nav.Item>
                </Nav>
              </Container>
            </Navbar>
          </Container>
        </PlanningProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
