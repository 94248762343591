import usePlanning from "context/PlanningContext";
import { useState } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";

const CreateSession = () => {
  const { createSession } = usePlanning();
  const [validated, setValidated] = useState(false);
  const [newSessionName, setNewSessionName] = useState("");
  const [showUserNames, setShowUserNames] = useState(false);

  const handleNameChange = (e: any) => {
    e.persist();
    setNewSessionName(e.target.value);
  };

  const handleShowUsersNamesChange = (e: any) => {
    e.persist();
    setShowUserNames(e.target.checked);
  };

  const handleSubmitCreate = (e: any) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    setValidated(true);
    if (form.checkValidity()) {
      createSession(newSessionName, showUserNames);
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <h1>Create Session</h1>
              </Card.Header>
              <Card.Body>
                <p>Please enter your new session name.</p>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmitCreate}
                >
                  <Form.Group className="mb-3" controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={newSessionName}
                      onChange={handleNameChange}
                      required
                      autoComplete="name"
                    />
                    <Form.Control.Feedback type="invalid">
                      Session Name is required
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Check
                      type="switch"
                      id="show-usernames"
                      label="Show Users Names"
                      checked={showUserNames}
                      onChange={handleShowUsersNamesChange}
                    />
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Button variant="primary" type="submit">
                      Create
                    </Button>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateSession;
