import React, { Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Loading from "components/Loading";
import Home from "domains/Home";
import Session from "domains/Sessions/Session";
import VerifiedRoute from "./verifiedRoute";
import Account from "domains/Account/account";
import ForgotPassword from "domains/Account/forgotPassword";
import Login from "domains/Account/login";
import Register from "domains/Account/register";
import ConfirmAccount from "domains/Account/confirmAccount";
import CreateSession from "domains/Sessions/createSession";
import Logout from "domains/Account/logout";
import UpdateProfile from "domains/Account/updateProfile";
import JoinSession from "domains/Sessions/joinSession";

const RouteTable = () => {
  const location = useLocation();
  return (
    <Suspense fallback={<Loading isLoading={true} />}>
      <Routes key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/session" element={<Session />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/confirmAccount" element={<ConfirmAccount />} />
        <Route element={<VerifiedRoute />}>
          <Route path="/createSession" element={<CreateSession />} />
          <Route path="/joinSession" element={<JoinSession />} />
          <Route path="/updateProfile" element={<UpdateProfile />} />
          <Route path="/account" element={<Account />} />
          <Route path="/logout" element={<Logout />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default RouteTable;
