import { Vote } from "interfaces/Planning";
import { useEffect, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";

type ResultsProps = {
  data: Vote[];
  showUserNames: boolean;
};

interface IChartData {
  title: string;
  value: number;
  color: string;
}

const Results = ({ data, showUserNames }: ResultsProps) => {
  const [chartData, setChartData] = useState<IChartData[]>([]);
  const countVotes = (
    arr: Vote[],
    property: keyof Vote
  ): { value: number; count: number }[] => {
    const voteCounts: Record<number, number> = {};

    arr.forEach((item) => {
      const value: any = item[property];
      voteCounts[value] = (voteCounts[value] || 0) + 1;
    });

    return Object.entries(voteCounts).map(([value, count]) => ({
      value: Number(value),
      count,
    }));
  };

  useEffect(() => {
    const voteCounts = countVotes(data, "vote");
    const dataParse: IChartData[] = [];
    const colours: string[] = ["#E38627", "#C13C37", "#6A2135"];

    voteCounts.forEach((vote, index) => {
      if (vote.count > 0) {
        dataParse.push({
          title: vote.value.toString(),
          value: vote.count,
          color: colours[index],
        });
      }
    });

    setChartData(dataParse);
  }, [data]);

  return (
    <>
      {chartData.length > 0 && (
        <PieChart
          data={chartData}
          label={({ dataEntry }) =>
            `${dataEntry.value} voted ${dataEntry.title}`
          }
          labelStyle={{ fontSize: "0.3em" }}
        />
      )}
    </>
  );
};

export default Results;
