import axios, { Method } from "axios";
import { Auth } from "aws-amplify";
import { useReducer, useCallback } from "react";

export interface IState {
  sending: boolean;
  data?: any;
  error?: any;
  validationFailures?: any;
}

const initialState: IState = {
  sending: false,
  data: null,
  error: null,
  validationFailures: null,
};

type Action =
  | { type: "send" }
  | { type: "response"; results: any }
  | { type: "error"; error: any; validationFailures: any }
  | { type: "reset" };

const reducer = (state: IState, action: Action): IState => {
  switch (action.type) {
    case "send":
      return {
        ...state,
        sending: true,
      };
    case "response":
      return {
        ...initialState,
        sending: false,
        data: action.results,
      };
    case "error":
      return {
        ...state,
        sending: false,
        error: action.error,
        validationFailures: action.validationFailures,
      };
    case "reset":
      return initialState;
  }
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-type": "application/json",
  },
});

const useContextHttp = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const resetState = useCallback(() => {
    delete axiosInstance.defaults.headers.common["Authorization"];
    dispatch({ type: "reset" });
  }, []);

  const isEmptyValidationErrors = (validationErrors: any) => {
    if (!validationErrors) {
      return true;
    }
    return Object.keys(validationErrors).length === 0;
  };

  const sendRequest = useCallback(
    async (url: string, method: Method, data?: any, requireAuth?: boolean) => {
      dispatch({ type: "send" });

      if (
        requireAuth &&
        !axiosInstance.defaults.headers.common["Authorization"]
      ) {
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        if (user) {
          axiosInstance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${user.signInUserSession.idToken.jwtToken}`;
        }
      }

      await axiosInstance({
        url: url,
        method: method,
        data: data,
      })
        .then((response) => {
          dispatch({ type: "response", results: response.data });
        })
        .catch((error: any) => {
          if (error.response) {
            switch (error.response.status) {
              case 400:
                dispatch({
                  type: "error",
                  error: !isEmptyValidationErrors(data.validationErrors)
                    ? null
                    : data.errorMessage,
                  validationFailures: data.validationErrors,
                });

                break;
              case 404:
                dispatch({
                  type: "error",
                  error: "Route does not exist",
                  validationFailures: null,
                });

                break;
              case 500:
                const isErrorMessage = typeof data === "string";

                dispatch({
                  type: "error",
                  error: isErrorMessage ? data : null,
                  validationFailures: null,
                });

                break;
              default:
                dispatch({
                  type: "error",
                  error: `Unexpected server error ${error.response.status}`,
                  validationFailures: null,
                });

                break;
            }
          } else {
            dispatch({
              type: "error",
              error: "The service is temporarily unavailable",
              validationFailures: null,
            });
          }
        });
    },
    []
  );

  return {
    isSending: state.sending,
    data: state.data,
    error: state.error,
    validationFailures: state.validationFailures,
    resetState: resetState,
    sendRequest: sendRequest,
  };
};

export default useContextHttp;
