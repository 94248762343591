import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../context/AuthContext";
import { useEffect } from "react";

const VerifiedRoute = () => {
  const { isAuthenticated } = useAuth();

  useEffect(() => {}, [isAuthenticated]);

  if (!isAuthenticated) return <Navigate to="/login" />;

  return <Outlet />;
};

export default VerifiedRoute;
