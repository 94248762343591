import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import {
  CloudDownloadFill,
  QuestionCircle,
  CheckCircle,
} from "react-bootstrap-icons";
import EstimateCard from "components/EstimateCard";
import usePlanning from "context/PlanningContext";
import UploadTicket from "components/UploadTickets";
import CountDown from "components/Countdown";
import Results from "components/Results";
import useAuth from "context/AuthContext";
import { ITicket, IBulkTicket } from "interfaces/Planning";

const votingPeriod: number = 30;
const downloadHeaders = [
  { label: "Jira Id", key: "key" },
  { label: "Summary", key: "title" },
  { label: "Estimation", key: "estimation" },
];

const Session = () => {
  const { profile } = useAuth();
  const {
    getSession,
    voteOnTicket,
    addTicket,
    bulkAddTickets,
    setSelectedTicket,
    deSelectTicket,
    removeTicket,
    startVoting,
    stopVoting,
    getVoteResults,
    allocateEstimate,
    finishVoting,
    allocatePollingId,
    resetPollingId,
    session,
    pollingId,
    votes,
  } = usePlanning();
  const [validated, setValidated] = useState(false);
  const [newTicketKey, setNewTicketKey] = useState("");
  const [newTicketDetails, setNewTicketDetails] = useState("");
  const [votingTicket, setVotingTicket] = useState<ITicket | undefined>();
  const [estimationTicket, setEstimationTicket] = useState<
    ITicket | undefined
  >();
  const [voteOnTicketActive, setVoteOnTicketActive] = useState(true);
  const [selectedValue, setSelectedValue] = useState(-2);
  const [addSingleTicketVisible, setAddSingleTicketVisible] = useState(false);
  const [bulkAddTicketVisible, setBulkAddTicketVisible] = useState(false);
  const [showVoteResult, setShowVoteResult] = useState(false);
  const [estimateValidated, setEstimateValidated] = useState(false);
  const [allocatedEstimate, setAllocatedEstimate] = useState("");

  const handleNewTicketKeyChange = (e: any) => {
    e.persist();
    setNewTicketKey(e.target.value);
  };

  const handleNewTicketDetailsChange = (e: any) => {
    e.persist();
    setNewTicketDetails(e.target.value);
  };

  const handleCreateTicket = (e: any) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    setValidated(true);
    if (form.checkValidity()) {
      addTicket(newTicketKey, newTicketDetails);
      setNewTicketKey("");
      setNewTicketDetails("");
      setAddSingleTicketVisible(false);
      startPolling();
    }
  };

  const handleValueSelected = (value: number) => {
    if (votingTicket) {
      setVoteOnTicketActive(false);
      setSelectedValue(-2);
      setSelectedValue(value);
      voteOnTicket(votingTicket.id, value);
    }
  };

  const handleUploadedData = (data: IBulkTicket[]) => {
    bulkAddTickets(session.code, data);
  };

  const handleCloseUpload = () => {
    setBulkAddTicketVisible(false);
    startPolling();
  };

  const handleAddSingleTicket = () => {
    setValidated(false);
    setAddSingleTicketVisible(true);
    setBulkAddTicketVisible(false);
    stopPolling();
  };

  const handleBulkAddTickets = () => {
    setAddSingleTicketVisible(false);
    setBulkAddTicketVisible(true);
    stopPolling();
  };

  const handleSelectTicket = (ticketId: string) => {
    setSelectedTicket(ticketId);
  };

  const handleDeselectTicket = (ticketId: string) => {
    deSelectTicket(ticketId);
  };

  const handleStartVoting = (ticketId: string) => {
    const ticket = session.tickets.find((x) => x.id === ticketId);
    setEstimationTicket(ticket);
    setVoteOnTicketActive(true);
    startVoting();
  };

  const handleAllocatedEstimateChange = (e: any) => {
    e.persist();
    setAllocatedEstimate(e.target.value);
  };

  const handleReEstimateTicket = (ticketId: string) => {
    setSelectedTicket(ticketId);
    setVoteOnTicketActive(true);
    startVoting();
  };

  const handleRemoveTicket = (ticketId: string) => {
    removeTicket(ticketId);
  };

  const handleSubmitEstimate = (e: any) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    setEstimateValidated(true);
    if (form.checkValidity() && estimationTicket) {
      setShowVoteResult(false);
      allocateEstimate(estimationTicket.id, parseFloat(allocatedEstimate));
      setAllocatedEstimate("");
      setSelectedValue(-2);
      setEstimationTicket(undefined);
      setEstimateValidated(false);
    }
  };

  const handleRevote = (ticketId: string) => {
    setShowVoteResult(false);
    setSelectedValue(-2);
    handleStartVoting(ticketId);
  };

  const handleFinishVoting = (ticketId: string) => {
    setVoteOnTicketActive(false);
    finishVoting(ticketId);
    getVoteResults(ticketId);
  };

  const stopPolling = () => {
    clearInterval(pollingId);
    resetPollingId();
  };

  const startPolling = () => {
    function refresh() {
      getSession(session.code);
    }
    const id = setInterval(refresh, 2000);
    allocatePollingId(id);
  };

  useEffect(() => {
    const ticket = session.tickets.find((x) => x.active);
    if (ticket) {
      setVotingTicket(ticket);
    } else {
      setVotingTicket(undefined);
    }
  }, [session]);

  useEffect(() => {}, [
    voteOnTicketActive,
    selectedValue,
    bulkAddTicketVisible,
    addSingleTicketVisible,
    pollingId,
    votingTicket,
  ]);

  useEffect(() => {
    let votingTicketId: string;
    const AdminVoteCycle = (ticketId: string) => {
      setTimeout(stopVoting, (votingPeriod + 8) * 1000);
      setTimeout(() => getVoteResults(ticketId), (votingPeriod + 8) * 1000);
    };

    const UserVoteCycle = () => {
      setTimeout(stopVoting, (votingPeriod + 5) * 1000);
    };

    if (session.votingActive && votingTicket && profile) {
      if (profile.userId === session.owner.userId) {
        votingTicketId = votingTicket.id;
        AdminVoteCycle(votingTicketId);
      } else {
        UserVoteCycle();
      }
    }
  }, [session.votingActive]);

  useEffect(() => {
    if (votes && votes.votes.length > 0) {
      setShowVoteResult(true);
    }
  }, [votes]);

  useEffect(() => {
    startPolling();
  }, []);

  return (
    <>
      <Container>
        <Modal show={showVoteResult}>
          <Modal.Header>Voting Results</Modal.Header>
          <Modal.Body>
            <Results data={votes.votes} showUserNames={session.showUserNames} />
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Col>
                <Button
                  variant="danger"
                  onClick={() => handleRevote(estimationTicket?.id || "")}
                >
                  Revote
                </Button>
              </Col>
              <Col>
                <Form
                  noValidate
                  validated={estimateValidated}
                  onSubmit={handleSubmitEstimate}
                >
                  <InputGroup className="mb-3">
                    <Button variant="outline-success" type="submit">
                      Allocate
                    </Button>
                    <Form.Control
                      aria-label="Allocate points here"
                      value={allocatedEstimate}
                      onChange={handleAllocatedEstimateChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Estimate is required
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
        <Row>
          <Col>
            <h1>{`Session - ${session.title} (${session.joinCode})`}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={9}>
            {profile?.userId === session.owner.userId && !votingTicket && (
              <>
                <Row>
                  <Col>
                    <Card>
                      <Card.Header>
                        <h2>Manage Tickets</h2>
                      </Card.Header>
                      <Card.Body>
                        {addSingleTicketVisible && (
                          <>
                            <p>
                              Please enter a title for the ticket you wish to
                              estimate.
                            </p>
                            <Form
                              noValidate
                              validated={validated}
                              onSubmit={handleCreateTicket}
                            >
                              <Form.Group className="mb-3" controlId="key">
                                <Form.Label>Jira ID</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={newTicketKey}
                                  onChange={handleNewTicketKeyChange}
                                  required
                                />
                                <Form.Control.Feedback type="invalid">
                                  Ticket key is required
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-3" controlId="title">
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={newTicketDetails}
                                  onChange={handleNewTicketDetailsChange}
                                  required
                                />
                                <Form.Control.Feedback type="invalid">
                                  Ticket title is required
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group as={Row}>
                                <Col>
                                  <Button variant="primary" type="submit">
                                    Add
                                  </Button>
                                </Col>
                              </Form.Group>
                            </Form>
                          </>
                        )}

                        <UploadTicket
                          showUpload={bulkAddTicketVisible}
                          uploadedData={handleUploadedData}
                          closeUpload={handleCloseUpload}
                        />
                        <Row>
                          <Col>
                            <Button
                              variant="primary"
                              className="margin-top"
                              onClick={() => {
                                handleAddSingleTicket();
                              }}
                            >
                              Add Single Ticket
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              variant="primary"
                              className="margin-top"
                              onClick={() => {
                                handleBulkAddTickets();
                              }}
                            >
                              Bulk Add Tickets
                            </Button>
                          </Col>
                          <Col>
                            {session &&
                              session.tickets &&
                              session.tickets.some(
                                (obj) =>
                                  obj.estimation !== undefined &&
                                  obj.estimation !== null
                              ) && (
                                <>
                                  <CSVLink
                                    className="btn btn-outline-success download-button margin-top"
                                    data={session.tickets}
                                    headers={downloadHeaders}
                                  >
                                    <CloudDownloadFill
                                      color="green"
                                      size={28}
                                    />
                                    Download
                                  </CSVLink>
                                </>
                              )}
                          </Col>
                        </Row>
                        {!votingTicket &&
                          session.tickets.length > 0 &&
                          profile.userId === session.owner.userId && (
                            <Row>
                              <Col>
                                <h3>Tickets</h3>
                                <ul className="clear-list button-line">
                                  {session.tickets.map((ticket) => (
                                    <li key={ticket.key}>
                                      <>
                                        {ticket.estimation ? (
                                          <>
                                            <Button
                                              variant="danger"
                                              className="margin-bottom-small margin-right"
                                              onClick={() =>
                                                handleRemoveTicket(ticket.id)
                                              }
                                            >
                                              Select
                                            </Button>
                                            <Button
                                              variant="danger"
                                              className="margin-bottom-small margin-right"
                                              onClick={() =>
                                                handleReEstimateTicket(
                                                  ticket.id
                                                )
                                              }
                                            >
                                              Re-Estimate
                                            </Button>
                                            {`${ticket.estimation} points for ${ticket.title}`}
                                          </>
                                        ) : (
                                          <>
                                            <Button
                                              variant="success"
                                              className="margin-bottom-small margin-right"
                                              onClick={() =>
                                                handleSelectTicket(ticket.id)
                                              }
                                            >
                                              Select
                                            </Button>
                                            {`${ticket.key} - ${ticket.title}`}
                                          </>
                                        )}
                                      </>
                                    </li>
                                  ))}
                                </ul>
                              </Col>
                            </Row>
                          )}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </>
            )}
            {profile?.userId === session.owner.userId && votingTicket && (
              <>
                <Row>
                  <Col>
                    <Button
                      variant="success"
                      onClick={() => handleStartVoting(votingTicket.id)}
                    >
                      Start Voting
                    </Button>
                  </Col>
                  <Col>
                    {session.votingActive ? (
                      <>
                        <Button
                          variant="info"
                          onClick={() => handleFinishVoting(votingTicket.id)}
                        >
                          Finish Voting
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="danger"
                          onClick={() => handleDeselectTicket(votingTicket.id)}
                        >
                          Cancel Voting
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              </>
            )}

            {votingTicket && (
              <>
                <Row>
                  <Col xs={12} md={9}>
                    <h3>Voting Time</h3>
                    <p>We will be voting on the following ticket.</p>
                    <p>
                      <strong>
                        {votingTicket.key} -{votingTicket.title}
                      </strong>
                    </p>
                  </Col>
                </Row>
                {session.votingActive && (
                  <>
                    <Row>
                      <Col xs={12} md={3}>
                        <CountDown time={votingPeriod} />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} sm={4} md={2} lg={2}>
                        <EstimateCard
                          value={0}
                          text="0"
                          active={voteOnTicketActive}
                          selectedValue={selectedValue}
                          onSelected={(e) => handleValueSelected(e)}
                        />
                      </Col>
                      <Col xs={6} sm={4} md={2} lg={2}>
                        <EstimateCard
                          value={0.5}
                          text="1/2"
                          active={voteOnTicketActive}
                          selectedValue={selectedValue}
                          onSelected={(e) => handleValueSelected(e)}
                        />
                      </Col>
                      <Col xs={6} sm={4} md={2} lg={2}>
                        <EstimateCard
                          value={1}
                          text="1"
                          active={voteOnTicketActive}
                          selectedValue={selectedValue}
                          onSelected={(e) => handleValueSelected(e)}
                        />
                      </Col>
                      <Col xs={6} sm={4} md={2} lg={2}>
                        <EstimateCard
                          value={2}
                          text="2"
                          active={voteOnTicketActive}
                          selectedValue={selectedValue}
                          onSelected={(e) => handleValueSelected(e)}
                        />
                      </Col>
                      <Col xs={6} sm={4} md={2} lg={2}>
                        <EstimateCard
                          value={3}
                          text="3"
                          active={voteOnTicketActive}
                          selectedValue={selectedValue}
                          onSelected={(e) => handleValueSelected(e)}
                        />
                      </Col>
                      <Col xs={6} sm={4} md={2} lg={2}>
                        <EstimateCard
                          value={5}
                          text="5"
                          active={voteOnTicketActive}
                          selectedValue={selectedValue}
                          onSelected={(e) => handleValueSelected(e)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} sm={4} md={2} lg={2}>
                        <EstimateCard
                          value={8}
                          text="8"
                          active={voteOnTicketActive}
                          selectedValue={selectedValue}
                          onSelected={(e) => handleValueSelected(e)}
                        />
                      </Col>
                      <Col xs={6} sm={4} md={2} lg={2}>
                        <EstimateCard
                          value={13}
                          text="13"
                          active={voteOnTicketActive}
                          selectedValue={selectedValue}
                          onSelected={(e) => handleValueSelected(e)}
                        />
                      </Col>
                      <Col xs={6} sm={4} md={2} lg={2}>
                        <EstimateCard
                          value={20}
                          text="20"
                          active={voteOnTicketActive}
                          selectedValue={selectedValue}
                          onSelected={(e) => handleValueSelected(e)}
                        />
                      </Col>
                      <Col xs={6} sm={4} md={2} lg={2}>
                        <EstimateCard
                          value={40}
                          text="40"
                          active={voteOnTicketActive}
                          selectedValue={selectedValue}
                          onSelected={(e) => handleValueSelected(e)}
                        />
                      </Col>
                      <Col xs={6} sm={4} md={2} lg={2}>
                        <EstimateCard
                          value={100}
                          text="100"
                          active={voteOnTicketActive}
                          selectedValue={selectedValue}
                          onSelected={(e) => handleValueSelected(e)}
                        />
                      </Col>
                      <Col xs={6} sm={4} md={2} lg={2}>
                        <EstimateCard
                          value={-1}
                          text="?"
                          active={voteOnTicketActive}
                          selectedValue={selectedValue}
                          onSelected={(e) => handleValueSelected(e)}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
          </Col>
          <Col xs={12} md={3}>
            <h3>Team Members</h3>
            <ul className="clear-list">
              {session.users.map((user) => {
                let userDiscData;
                if (session.votingActive) {
                  if (session.showUserNames) {
                    userDiscData = <>{user.status}</>;
                  } else {
                    userDiscData =
                      user.status === "?" ? (
                        <QuestionCircle size={28} />
                      ) : (
                        <CheckCircle size={28} />
                      );
                  }
                } else {
                  userDiscData = <>{user.status}</>;
                }

                return (
                  <li key={user.userId}>
                    <span className="user-status-disc">{userDiscData}</span>
                    {user.name}
                  </li>
                );
              })}
            </ul>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Session;
