import { IBulkTicket } from "interfaces/Planning";
import { useEffect, useState } from "react";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { Result } from "react-spreadsheet-import/types/types";

type UploadTicketsProps = {
  showUpload: boolean;
  uploadedData(tickets: IBulkTicket[]): void;
  closeUpload(): void;
};

const fields = [
  {
    label: "Jira Id",
    key: "key",
    alternateMatches: ["Issue key"],
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "required",
        errorMessage: "Jira Id is required",
        level: "error",
      },
    ],
  },
  {
    label: "Title",
    key: "title",
    alternateMatches: ["Summary"],
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "required",
        errorMessage: "Title is required",
        level: "error",
      },
    ],
  },
] as const;

const UploadTicket = ({
  showUpload,
  uploadedData,
  closeUpload,
}: UploadTicketsProps) => {
  const [isOpen, setIsOpen] = useState(showUpload);

  const handleOnClose = () => {
    setIsOpen(false);
    closeUpload();
  };

  const handleOnSubmit = (data: Result<"key" | "title">, file: File) => {
    const tickets: IBulkTicket[] = [];
    data.validData.forEach((ticket) => {
      const newTicket: IBulkTicket = {
        key: ticket.key as string,
        title: ticket.title as string,
      };
      tickets.push(newTicket);
    });

    uploadedData(tickets);
    setIsOpen(false);
  };

  useEffect(() => {
    setIsOpen(showUpload);
  }, [showUpload]);

  useEffect(() => {}, [isOpen]);

  return (
    <>
      <ReactSpreadsheetImport
        isOpen={isOpen}
        onClose={handleOnClose}
        onSubmit={handleOnSubmit}
        fields={fields}
      />
    </>
  );
};

export default UploadTicket;
