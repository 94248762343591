import { Auth } from "aws-amplify";
import useContextHttp from "infrastructure/useContextHttp";
import {
  IAWSProfile,
  ICreateProfile,
  IProfile,
  IUpdateProfile,
} from "interfaces/Auth";
import {
  createContext,
  ReactNode,
  useState,
  useEffect,
  useMemo,
  useContext,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IAuthContext } from "./IAuthContext";
import { IBaseSession } from "interfaces/Planning";

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  let navigate = useNavigate();
  const {
    data: profileResponse,
    error: profileError,
    sendRequest: profileRequest,
  } = useContextHttp();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<IAWSProfile>();
  const [profile, setProfile] = useState<IProfile>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);

  const location = useLocation();

  useEffect(() => {
    if (error) setError(null);
    const validateUser = async () => {
      if (isAuthenticated) {
        const { attributes } = await Auth.currentAuthenticatedUser();
        if (attributes) {
          const user: IAWSProfile = {
            authId: attributes.sub,
            email: attributes.email,
            verified: attributes.email_verified,
          };
          setUser({ ...user });
          //getCleanUserProfile();
        } else {
          setUser(undefined);
          setProfile(undefined);
        }
      }
      setLoadingInitial(false);
    };

    validateUser();
  }, [location.pathname, error]);

  const logIn = (email: string, password: string) => {
    setLoading(true);
    Auth.signIn(email, password)
      .then((_user) => {
        const user: IAWSProfile = {
          authId: _user.attributes.sub,
          email: _user.attributes.email,
          verified: _user.attributes.email_verified,
        };
        setIsAuthenticated(true);
        setUser({ ...user });
        getCleanUserProfile();
      })
      .catch((_error) => {
        if (_error.code === "UserNotConfirmedException") {
          const unVerifiedUser: IAWSProfile = {
            authId: "",
            email: email,
            verified: false,
          };
          setUser({ ...unVerifiedUser });
          navigate("/confirmAccount");
        }
        setIsAuthenticated(false);
        setError(_error);
      })
      .finally(() => setLoading(false));
  };

  const sendVerificationCodeForForgotPassword = (username: string) => {
    if (username !== "") {
      Auth.forgotPassword(username)
        .then((res: any) => {})
        .catch((_error) => {});
    }
  };

  const updatePassword = (
    username: string,
    verificationCode: string,
    password: string
  ) => {
    if (username !== "" && verificationCode !== "" && password !== "") {
      Auth.forgotPasswordSubmit(username, verificationCode, password)
        .then((res: any) => {})
        .catch((_error) => {});
    }
  };

  const getToken = (): string => {
    let token = "";
    setLoading(true);
    Auth.currentAuthenticatedUser()
      .then((_user) => {
        token = _user.signInUserSession.idToken.jwtToken;
      })
      .catch((_error) => {})
      .finally(() => {
        setLoadingInitial(false);
      });
    return token;
  };

  const signUp = (name: string, username: string, password: string) => {
    const email = username;
    setLoading(true);
    Auth.signUp({
      username,
      password,
      attributes: {
        email,
      },
      autoSignIn: {
        enabled: true,
      },
    })
      .then((user) => {
        const authUser: IAWSProfile = {
          authId: user.userSub,
          email: username,
          verified: user.userConfirmed,
        };
        setUser({ ...authUser });
        setIsAuthenticated(true);

        const registeredProfile: ICreateProfile = {
          authId: user.userSub,
          name: name,
          email: username,
          verified: user.userConfirmed,
        };
        profileRequest(
          `${process.env.REACT_APP_API_ENDPOINT}/account/CreateAccount`,
          "POST",
          registeredProfile,
          false
        );
      })
      .catch((_error) => {
        setIsAuthenticated(false);
        setError(_error);
      })
      .finally(() => setLoading(false));
  };

  const confirmSignUp = (code: string) => {
    if (user) {
      Auth.confirmSignUp(user.email, code)
        .then(() => {
          setIsAuthenticated(true);
          navigate("/account");
        })
        .catch((_error) => setError(_error));
    }
  };

  const resendConfCode = () => {
    if (user) {
      Auth.resendSignUp(user.email)
        .then(() => {})
        .catch((_error) => {
          setError(_error);
        });
    }
  };

  const logOut = async () => {
    setUser(undefined);
    setProfile(undefined);
    await Auth.signOut({ global: true });
    setIsAuthenticated(false);
    navigate("/");
  };

  const resetAuth = () => {
    setLoading(false);
    setError("");
  };

  const reloadProfile = () => {
    getCleanUserProfile();
  };

  const updateProfile = (updatedProfile: IUpdateProfile) => {
    if (updatedProfile) {
      profileRequest(
        `${process.env.REACT_APP_API_ENDPOINT}/account/UpdateProfile`,
        "POST",
        updatedProfile,
        true
      );
    }
  };

  useEffect(() => {
    if (profileResponse) {
      mapResponseToProfile(profileResponse);
    }
    if (profileError) {
      resetProfile();
    }
  }, [profileResponse, profileError]);

  useEffect(() => {
    if (isAuthenticated && user) {
      if (user.verified) {
        getCleanUserProfile();
      }
    } else {
      setUser(undefined);
      setProfile(undefined);
    }
  }, [isAuthenticated]);

  const resetProfile = () => {
    setIsAuthenticated(false);
    setProfile(undefined);
    setUser(undefined);
    Auth.signOut({ global: true });
  };

  const mapResponseToProfile = (response: any) => {
    const profile = response.account as IProfile;
    const sessions = response.sessions as IBaseSession[];
    profile.sessions = sessions;
    setProfile({ ...profile });
    if (user) {
      navigate(user.verified ? "/account" : "/confirmAccount");
    }
  };

  const getCleanUserProfile = () => {
    profileRequest(
      `${process.env.REACT_APP_API_ENDPOINT}/account/Account`,
      "GET",
      null,
      true
    );
  };

  const memoedValue = useMemo(
    () => ({
      user,
      profile,
      loading,
      error,
      isAuthenticated,
      logIn,
      getToken,
      signUp,
      confirmSignUp,
      resendConfCode,
      logOut,
      resetAuth,
      sendVerificationCodeForForgotPassword,
      updatePassword,
      updateProfile,
      reloadProfile,
    }),
    [isAuthenticated, profile, error, user]
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}
